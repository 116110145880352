import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_21lung from "../Forms/FormsImg_21lung";
import Table from "../Table/Table";
import Hero from '../Hero';

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserMultiLung from "../user/UserMultiLung";
const MainDashImg_21lung = () => {
  return (
    <div className="MainDash">
            <Hero />

      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_21lung/>
      <UserMultiLung/>
    
    </div>
  );
};

export default MainDashImg_21lung;



