import {
    CalendarToday,
    LocationSearching,
    MailOutline,
    PermIdentity,
    PhoneAndroid,
    Publish,
  } from "@material-ui/icons";
  import { Link } from "react-router-dom";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate, UilEnvelopeEdit,UilMapPinAlt} from "@iconscout/react-unicons";

import YoutubeEmbed from "../youtube";
  
  export default function UserHomepage() {
    return (
      <div className="">
        <div className="">
          
          <h1 className=" uppercase text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center pb-10">Welcome to AI For Cancers</h1>
          <Link to="/newUser">
          </Link>
        </div>
        <div className="userContainer">
          <div className="p-5 border-2 border-slate-200 rounded sm:m-10 md:m-15 lg:m-40	">
            <div className="">
               <div className="">
                <span className="text-base font-bold">DISCLAIMER: This Website Is Meant For Research And Personal Testing Only!!</span>
                <p>{"\n"}</p>
                <p className="text-base font-bold">You pay for food, you don’t pay for health.</p>
                <p className="">The authors do not focus on money. From the moment we are born, we are set to face the inevitable – death. We focus on enhancing the life of patients so that they can build and reinforce relationships with their loved ones Life is not about making money. It is enhancing the quality of life for every single being.</p>
                <p className="">These trained AI models are freely available for use.</p>
                <p className="">We provide a platform to integrate healthcare professionals, computer scientists and researchers to better humanity. We encourage adoption of AI across health systems to improve accuracy of clinical diagnosis, decisions and treatments. We envisage an amalgamation of vibrant AI Systems with medical techniques.</p>
                <span>Our aim is to improve the life of patients' one at a time. Each patient’s life is as important to us as it is to you.</span>
                
                <span></span>


                <span className="userShowUserTitle"></span>
              </div>
            </div>
            <div className="userShowBottom">
              <div className="userShowInfo">
                <PhoneAndroid className="userShowIcon" />
                <span className="userShowInfoTitleBold">Data Credentials:</span>
                <span className="userShowInfoTitle">Kaggle</span>
              </div>

              <div className="userShowInfo">
                <MailOutline className="userShowIcon" />

                <span className="userShowInfoTitleBold" >Contact:</span>

                <a href = 'aiforcancer@gmail.com'>
                  <span className="userShowInfoTitle">aiforcancer@gmail.com</span>              
                </a>
                </div>
              <div className="userShowInfo">
                <UilMapPinAlt/>
                <span className="userShowInfoTitle">New York | USA</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }