import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pic from "../crab.png";
import {
  faEnvelope,
  faBrain,
  faHouse,
  faLungs,
  faPoo,
  faLungsVirus,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='sticky top-0 bg-slate-500 flex justify-between items-center h-24 w-full mx-auto px-4 text-white'>
        <a className="w-full flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" href="/">
          <img src={pic} alt="" className="w-10 h-10 p-2 bg-indigo-500 rounded-full"/>
          <span className="ml-3 text-white text-2xl">AIC</span>
        </a>
      <ul className='hidden md:flex text-center	'>
        <li className='p-4'><a className="hover:text-gray-900" href="/">
            <FontAwesomeIcon icon={faHouse} className="px-2" style={{ fontSize: "16px" }} />
            HomePage
          </a></li>
        <li className='p-4'><a className=" hover:text-gray-900" href="/LungCancer">
            <FontAwesomeIcon icon={faLungs} className="px-2" style={{ fontSize: "16px" }} />
            LungCancer
          </a></li>
        <li className='p-4'><a className=" hover:text-gray-900" href="/ColerectalCancer">
            <FontAwesomeIcon icon={faPoo} className="px-2" style={{ fontSize: "16px" }} />
            ColerectalCancer
          </a></li>
        <li className='p-4'><a className=" hover:text-gray-900" href="/BrainCancer">
            <FontAwesomeIcon icon={faBrain} className="px-2" style={{ fontSize: "16px" }} />
            BrainCancer
          </a></li>
        <li className='p-4'><a className=" hover:text-gray-900" href="/MultiLung">
            <FontAwesomeIcon icon={faLungsVirus} className="px-2" style={{ fontSize: "16px" }}/>
            MultiLung(TBC)
          </a></li>
          <li className='p-4'><a className=" hover:text-gray-900" href="/Contact">
            <FontAwesomeIcon icon={faEnvelope} className="px-2" style={{ fontSize: "16px" }} />
            Contact
          </a></li>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'fixed right-0 top-20 w-[60%] h-full border-r border-r-slate-500 bg-slate-500	 ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
      {/* <a className="m-5 w-full flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" href="/">
          <img src={pic} alt="" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"/>
          <span className="ml-3 text-white text-2xl">AIC</span>
        </a>          */}

          <li className='p-4'><a className="mr-5 hover:text-gray-900" href="/">
            <FontAwesomeIcon icon={faHouse} className="px-2" style={{ fontSize: "16px" }} />
            HomePage
          </a></li>
        <li className='p-4 border-b border-gray-600'><a className="mr-5 hover:text-gray-900" href="/LungCancer">
            <FontAwesomeIcon icon={faLungs} className="px-2" style={{ fontSize: "16px" }} />
            LungCancer
          </a></li>
        <li className='p-4 border-b border-gray-600'><a className="mr-5 hover:text-gray-900" href="/ColerectalCancer">
            <FontAwesomeIcon icon={faPoo} className="px-2" style={{ fontSize: "16px" }} />
            ColerectalCancer
          </a></li>
        <li className='p-4 border-b border-gray-600'><a className="mr-5 hover:text-gray-900" href="/BrainCancer">
            <FontAwesomeIcon icon={faBrain} className="px-2" style={{ fontSize: "16px" }} />
            BrainCancer
          </a></li>
        <li className='p-4 border-b border-gray-600'><a className="mr-5 hover:text-gray-900" href="/MultiLung">
            <FontAwesomeIcon icon={faLungsVirus} className="px-2" style={{ fontSize: "16px" }}/>
            MultiLung(TBC)
          </a></li>
          <li className='p-4 border-b border-gray-600'><a className="mr-5 hover:text-gray-900" href="/Contact">
            <FontAwesomeIcon icon={faEnvelope} className="px-2" style={{ fontSize: "16px" }} />
            Contact
          </a></li>
      </ul>
    </div>
  );
};

export default Navbar;