import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_18brain from "../Forms/FormsImg_18brain";
import Table from "../Table/Table";
// import crab from
import Hero from '../Hero';

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MainDashImg_18brain = () => {
  return (
    <div className="MainDash">

<Hero />

      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_18brain/>

    </div>
  );
};

export default MainDashImg_18brain;
