import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_15_18_19_MultiBrain from "../Forms/FormsImg_15_18_19_MultiBrain";
import Table from "../Table/Table";
// import crab from

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from "../user/User";
const MainDashImg_15_18_19_MultiBrain = () => {
  return (
    <div className="MainDash">
      <h1>
      <UilHeartRate size={70} />
        AI FOR CANCER
      </h1>
      
      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_15_18_19_MultiBrain/>

    </div>
  );
};

export default MainDashImg_15_18_19_MultiBrain;
