// https://fontawesome.com/icons/staff-snake?s=solid&f=classic
// https://iconscout.com/unicons/explore/line

import './App.css'
import MainDash from './components/MainDash/MainDash';
import MainDash0 from './components/MainDash/MainDash0';
import MainDashImg from './components/MainDash/MainDashImg';
import Hero from './components/Hero';
import MainDashImg_1skin from './components/MainDash/MainDashImg_1skin';
import MainDashImg_3lung from './components/MainDash/MainDashImg_3lung';
import MainDashImg_4colon from './components/MainDash/MainDashImg_4colon';
import MainDashImg_18brain from './components/MainDash/MainDashImg_18brain';
import MainDashImg_21lung from './components/MainDash/MainDashImg_21lung';
import MainDashImg_15_18_19_MultiBrain from './components/MainDash/MainDashImg_15_18_19_MultiBrain';


import MainDashDocker from './components/MainDash/MainDashDocker';
import MainDashImgDocker from './components/MainDash/MainDashImgDocker';
import RightSide from './components/RigtSide/RightSide';
import Navbar from './components/Navbar';
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";
import RecommendIcon from '@mui/icons-material/Recommend';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColonSign,faLungs, faHeart, faHeartbeat, faVenus, faHandDots, faFootball , faPoo, faFootballBall, faBeer, faLungsVirus,faHeartPulse, faVirus} from '@fortawesome/free-solid-svg-icons'


import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import UserList from './components/userList/UserList';
import ImgUpload from './components/ImgUpload/ImgUpload';
import MainDashContact from './components/MainDash/MainDashContact';



function App() {
  return (
  <Router>
    <div className="App">
      <div className="AppGlass">
        <Navbar/>

        <Switch>
          <Route exact path="/" component={MainDash0} />

        </Switch>
        <Switch>
          <Route path ="/BreastCancer" >
          <MainDash/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/SkinCancer" >
          <MainDashImg_1skin/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/LungCancer" >
          <MainDashImg_3lung/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/ColerectalCancer" >
          <MainDashImg_4colon/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/BrainCancer" >
          <MainDashImg_18brain/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/BrainCancer_15_18_19_MultiBrain" >
          <MainDashImg_15_18_19_MultiBrain/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/MultiLung" >
          <MainDashImg_21lung/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/Contact" >
          <MainDashContact/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/Docker1" >
          <MainDashImg/>
          </Route>
        </Switch>
        <Switch>
          <Route path ="/Docker2" >
          <MainDashImg/>
          </Route>
        </Switch>
      </div>
        </div>
    </Router>
  );
}

export default App;
