import React from "react";
import {
  UilSignOutAlt,
  UilEstate,
  UilHome,
  UilVenus,
  UilHeartRate,
} from "@iconscout/react-unicons";

const Hero = () => {
  return (
    <div className="">
      <div className="flex flex-col items-center	content-center">
        <div className="icon mt-6 mb-4">
          <UilHeartRate size={100} />
        </div>
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl flex-auto mb-6">
          AI FOR CANCER
        </h1>
        <h2 className="text-xl font-bold text-blue-500 text-center uppercase pb-10">
          Make a Prediction
        </h2>
      </div>
    </div>
  );
};

export default Hero;
