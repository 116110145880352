import React from 'react';
import ImageUpload from '../ImgUpload/ImgUpload';
import DragAndDrop from '../DragDrop/DragAndDrop';

export default function Forms() {
  const [num1, setNum1] = React.useState(0);
  const [num2, setNum2] = React.useState(0);
  const [num3, setNum3] = React.useState(0);
  const [num4, setNum4] = React.useState(0);
  console.log(num1, num2, num3, num4)
  const [result, setResult] = React.useState(0);
  const handlesubmit = (e) => {
          e.preventDefault();
          console.log(num1, num2, num3, num4)
          //send these numbers to the backend
          const data = {
              "num1": num1,
              "num2": num2,
              "num3": num3,
              "num4": num4
          }
          fetch('http://localhost:7001/multiply', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
          })
          .then(res => res.json())
          .then(data => {
              console.log(data)
              setResult(data.result)
          })
          .catch(err => {
              console.log(err)
          })
  }


  return (
    <div className='create'>Forms

    <h2>Make a Prediction</h2>
    <form 
      onSubmit={handlesubmit}
    >
        <label >Parameter 1</label>
        <input 
          type="text"
          required
          onChange={(e) => setNum1(e.target.value)}
          

        />
        <label>Parameter 2:</label>
        <textarea
        required
        onChange={(e) => setNum2(e.target.value)}
        ></textarea>
        <label>Parameter 3</label>
        <textarea
        onChange={(e) => setNum3(e.target.value)}
        required
        ></textarea>
        <label>Parameter 4</label>
        <select 
        onChange={(e) => setNum4(e.target.value)}
        >
            <option value="1">1</option>
            <option value="2">2</option>
        </select>


        {/* <ImageUpload/> */}

        {/* <ImageUpload data={data} dispatch={dispatch} /> */}
        <button>
            RUN MULTIPLICATION
        </button>
        <div>
          Result is: 
        </div>
        {result}
        <textarea
        value={result}
        ></textarea>

        {/* <DragAndDrop/> */}


    </form>
    
    </div>
  )
}
