import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

export default function User3_lung() {
  return (
    <div className="w-full md:w-1/2">
      <div className=" m-5 justify-center">
        <h1 className="font-bold text-center">Model Details</h1>

      </div>
      <div className="userContainer ">
        <div className="userShow p-5 border-2 border-slate-200 rounded">
          <div className="userShowBottom ">
            <span className="userShowTitle">Data Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle wrap">Model 1 | Accuracy: 99.98% | Output Predictions : Tuberculosis, Covid, Lung Opacity</span>
            </div>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle wrap">Model 1 | Accuracy: 88.98% | Output Predictions : Viral Pneumonia, Bacterial Pneumonia</span>
            </div>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Category: 3</span>
            </div>

            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitleBold">Cancer Predictions:</span>
              <span className="userShowInfoTitle">https://www.kaggle.com/uciml/breast-cancer-wisconsin-data </span>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitleBold">Code Credentials:</span>
              </div>
            <div className="userShowInfo">
              <LocationSearching className="userShowIcon" />
              <span className="userShowInfoTitle">New York | USA</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}