import React from "react";
import ImageUpload from "../ImgUpload/ImgUpload";
import DragAndDrop from "../DragDrop/DragAndDrop";
import { useState } from "react";
import { useEffect, useRef } from "react";
import axios from "axios";
export default function MainDashImg_3lung() {
  const [image, setImage] = useState(null);
  const [num1, setNum1] = React.useState("");
  const myCanvas = useRef();
  console.log(image);
  console.log(num1);
  function handleImage(event) {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    console.log(file);
    reader.onload = function () {
      console.log("here");
      const img = new Image();
      img.src = reader.result;
      //convert img.src to a string that can be sent to backend
      setImage(img.src);
    };
    reader.readAsDataURL(file);
  }
  async function handleApi(e) {
    e.preventDefault();
    console.log(image);
    // const res = await fetch('http://localhost:7003/calculatepixels', {
    const res = await fetch("https://api003.aiforcancer.com/calculatepixels", {
      // const res = await fetch('https://5003.aiforcancer.com/calculatepixels', {

      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ image }),
    });
    const data = await res.json();
    console.log(data);
    setNum1(data.result);
  }
  useEffect(() => {
    const context = myCanvas.current.getContext("2d");
    const image1 = new Image();
    image1.src = image
      ? image
      : "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg";
    image1.onload = () => {
      context.drawImage(image1, 50, 0, 200, 150);
    };
  }, [image]);
  return (
      <div className="w-full md:w-full flex flex-col items-center content-center">
        <div className="p-8 bg-white">
          <div className="flex flex-col border border-gray-300 bg-gray-100 rounded-lg">
            <div className="h-auto flex justify-center items-center border border-dashed border-gray-400 col-span-2 m-2 rounded-lg bg-no-repeat bg-center bg-origin-padding bg-cover">
              <canvas className="w-auto h-auto" ref={myCanvas}  />
            </div>
            <div className="rounded-l-lg p-4 bg-gray-200 flex flex-col justify-center items-center border-0 border-r border-gray-300">
              {" "}
              <label
                class="cursor-pointer hover:opacity-80 inline-flex items-center shadow-md my-2 px-2 py-2 bg-gray-900 text-gray-50 border border-transparent
        rounded-md font-semibold text-xs uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none 
       focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150">
                <input className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700" type="file" name="file" onChange={handleImage}/>
              </label>
            </div>
          </div>
         
        </div>
        <div className="div py-5">
            {" "}
            <button className="cursor-pointer hover:opacity-80 inline-flex items-center shadow-md my-2 px-2 py-2 bg-gray-900 text-gray-50 border border-transparent
        rounded-md font-semibold text-xs uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none 
       focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150 "onClick={handleApi}> Submit </button>
          </div>

          <div className="flex flex-col w-full items-center justify-center">
            <div className="w-auto text-center m-2">Prediction</div>
            <div className="w-auto">
              <textarea className="text-red-600 border-2 w-full p-2" value={num1}> </textarea>
            </div>
          </div>
      </div>
  );
}
