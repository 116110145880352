import React from "react";
import Cards from "../Cards/Cards";
import Forms from "../Forms/Forms";
import Table from "../Table/Table";
import UserHomepage from "../user/UserHomepage";
import {UilHeartRate} from "@iconscout/react-unicons";
import Hero from '../Hero';

import "./MainDash.css";
import UserContact from "../user/UserContact";
const MainDashContact = () => {
  return (
    <div className="MainDash">
   
   <div className="flex flex-col items-center	content-center">
        <div className="icon mt-6 mb-4">
          <UilHeartRate size={100} />
        </div>
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl flex-auto mb-6">
          AI FOR CANCER
        </h1>

      {/* <Cards /> */}
      {/* <Table /> */}
      {/* <Forms/> */}
      <UserContact/>
    </div>
    </div>

  );
};

export default MainDashContact;
